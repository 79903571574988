/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Images
import gsnLogo from "assets/images/logos/gray-logos/GSN Logo.png";
import mapidLogo from "assets/images/logos/gray-logos/Logo MAPID.png";
import ugmLogo from "assets/images/logos/gray-logos/Logo UGM.png";
import bpnLogo from "assets/images/logos/gray-logos/Logo_BPN-KemenATR_(2017).png";
import oreconLogo from "assets/images/logos/gray-logos/Orecon Logo.png";
import twenteLogo from "assets/images/logos/gray-logos/University Twente Logo.png";


function Featuring() {
  return (
    <MKBox component="section" pt={12}>
      <Container>
      <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
          <MKBox component="img" src={gsnLogo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
          <MKBox component="img" sx={{paddingTop: "15px"}} src={mapidLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
          <MKBox component="img"sx={{paddingTop:"0px", padding:"35px", marginTop:"-50px"}} src={ugmLogo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
          <MKBox
              component="img"
              src={oreconLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
          <MKBox component="img" src={bpnLogo} sx={{paddingTop:"0px", padding:"35px", marginTop:"-50px"}}  alt="Vodafone" width="100%" opacity={0.6}  />
          </Grid>
          {/* <Grid item xs={6} md={4} lg={2}>
          <MKBox
              component="img"
              src={twenteLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Featuring;
