/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import logoPlace from "assets/images/Logo-Petain-Place.png";
function DesignValues() {

    return (
        <MKBox component="section" my={2} py={2}>
            <Container>
                <Grid
                    container
                    item
                    xs={12}
                    lg={9}
                    flexDirection="column"
                    alignItems="center"
                    sx={{ textAlign: "center", my: 2, mx: "auto", px: 0.75 }}
                >
                    <MKTypography variant="h1" fontWeight="bold">
                        OUR CORE VALUE

                    </MKTypography>
                    <MKTypography variant="h4" color="text">
                        Petain Organizational DNA
                    </MKTypography>
                </Grid>
            </Container>
        </MKBox>
    );
}

export default DesignValues;
