/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import bgBack from "assets/images/section-5-4.png";
function Information() {
  const bn46Style = {
    width: '220px',
    height: '80px',
    // Add any additional styles here
  };
  const bn47Style = {
    width: '250px',
    height: '11 0px',
    // Add any additional styles here
  };
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
            {/* <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                   icon="public"
                    title="GEO Advantage"
                    description="Explore our Petain Atlas, brimming with data from street-level to global insights, all at your fingertips for smart exploring and savvy decisions."
                  />
                </MKBox>
              </Grid> */}
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="DATA COMMUNITY"
                    description="Engage with our data users within the community.
                    Your data could be useful for everyone. Bring out the collected and processed data through publication. Made those location insights accessible for public."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                icon="public" 
                    title="DATA ANALYTIC"
                    description="Examine your data in various ways on the map."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5} >
                  <DefaultInfoCard
             icon="public"
                    title="DATA VISUALIZATION AND ANALYSIS"
                    description="Ilustrate and probe every single data on your projects for enhancing business decision-making,Involve various data sources to create, edit, and perform dynamis analysis on your geospatial projects. Take control of your project by determining who are in charge and define data restriction."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                icon="public"
                    title="INTEGRATION AND DATA COLLECTION"
                    description="A Complete Data Lake
                    GEO MAPID gathers abundant amount of data in a friendly user-interface design. Integrate any data, survey, sensor, and many more in an all-in-one location data display collaboratively.."
                  />
                </MKBox>
              </Grid>

            </Grid>
          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bgBack}
              // title="Get insights on Search"
              // description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              // action={{
              //   type: "internal",
              //   // route: "pages/company/about-us",
              //   // color: "info",
              //   // label: "find out more",
              // }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
