/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

// Presentation page components
import ExampleCard from "pages/Presentation/components/ExampleCard";

// Data
import data from "pages/Presentation/sections/data/designBlocksData";
import FilledInfoCard from "examples/Cards/InfoCards/FilledInfoCard";
import CardActions from "@mui/material/CardActions";
import MKButton from "components/MKButton";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
function DesignFeatures() {
  const renderData = data.map(({ title, description, items }) => (
    <Grid container spacing={3} sx={{ mb: 10 }} key={title}>
      <Grid item xs={12} lg={3}>
        <MKBox position="sticky" top="100px" pb={{ xs: 2, lg: 6 }}>
          <MKTypography variant="h3" fontWeight="bold" mb={1}>
            {title}
          </MKTypography>
          <MKTypography variant="body2" fontWeight="regular" color="secondary" mb={1} pr={2}>
            {description}
          </MKTypography>
          <CardActions sx={{ justifyContent: "left", bgcolor: "white", color: "white" }}>
          <MKButton href="/pages/business-solution" variant="gradient" color="light" sx={{
            transition: "transform 0.3s ease",
            "&:hover": {
              transform: "translateY(-5px)",
            }
          }}>
            Learn More
          </MKButton>
        </CardActions>
        </MKBox>
      </Grid>

      <Grid item xs={12} lg={9}>
        <Grid container spacing={3}>
          {items.map(({ icon, name, label, route, pro, description }) => (
            <Grid item xs={12} md={4} sx={{ mb: 2 }} key={name}>
                <DefaultInfoCard
                  variant="gradient"
                  color="info"
                  icon={icon}
                  title={name}
                  description={description}
          
                />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  ));

  return (
    <MKBox component="section" my={6} py={6}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 6, mx: "auto", px: 0.75 }}
        >
          {/* <MKBadge
            variant="contained"
            color="info"
            badgeContent="Infinite combinations"
            container
            sx={{ mb: 2 }}
          /> */}
          <MKTypography variant="h1" fontWeight="bold">
            Exploring GIS Potential for Strategic Decision Making
          </MKTypography>
          {/* <MKTypography variant="body1" color="text">
            We have created multiple options for you to put together and customise into pixel
            perfect pages.
          </MKTypography> */}
        </Grid>
      </Container>
      <Container sx={{ mt: 6 }}>{renderData}</Container>
    </MKBox>
  );
}

export default DesignFeatures;
