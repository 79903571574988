/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import bgBack from "assets/images/logo-insight.webp";
import SectionInfoCard from "examples/Cards/InfoCards/SectionInfoCard";
function InformationInsight() {
  return (
    <MKBox component="section">
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} md={5} sx={{ mr: "auto" }}>
            <Grid container justifyContent="flex-start">
              <Grid>
                <MKBox position="relative">
                  <MKBox component="img" src={bgBack} alt="macbook" width="100%" sx={{marginTop:"-70px"}} />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} lg={6}>
            <MKBox>
              <SectionInfoCard
                icon="public"
                title="GEO Insight"
                description="Geo Insight: a report-based analysis facilitating the interpretation and integration of geospatial data into user-friendly formats for enhanced understanding and utilization of through easy-to-understand and easily accessible formats.
                A report-based analysis that helps you interpret and leverage geospatial data in an easy-to-access and understandable way."
              />
            </MKBox>

          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InformationInsight;
