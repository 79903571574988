/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import team1 from "assets/images/integraph.png";
import team2 from "assets/images/section-5-4.png";
import team3 from "assets/images/logo-info-mapid.png";
import team4 from "assets/images/3d.png";
import TransparentBlogCard from "examples/Cards/BlogCards/TransparentBlogCard";
function Team() {
  return (
    <MKBox
      component="section"
      variant="info"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
            Effortless Integration of Advanced Mapping Solutions for Businesses
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
            <TransparentBlogCard
              image={team1}
              title="Rover raised $65 million"
              description="Combine your map view with statistical graphs and diagrams."
              action={{
                type: "internal",
                // route: "/pages/blogs/author",
                color: "info",
                // label: "read more",
              }}
            />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
            <TransparentBlogCard
              image={team4}
              title="Rover raised $65 million"
              description="A brand new dimension experience to interpret dynamics data"
              action={{
                type: "internal",
                // route: "/pages/blogs/author",
                color: "info",
                // label: "read more",
              }}
            />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <TransparentBlogCard
              image={team3}
              title="Rover raised $65 million"
              description="Apply some analytical features such as length measure, area, elevation profile, and radius analysis"
              action={{
                type: "internal",
                // route: "/pages/blogs/author",
                color: "info",
                // label: "read more",
              }}
            />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <TransparentBlogCard
              image={team2}
              title="Rover raised $65 million"
              description="Understand any nearby location insights with one click. Compare one location to another through interactive visualization"
              action={{
                type: "internal",
                // route: "/pages/blogs/author",
                color: "info",
                // label: "read more",
              }}
            />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
