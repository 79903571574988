import React from "react";
import { Grid, Card, CardContent, Typography, Container, Box } from "@mui/material";
import { Headphones, Spa, RocketLaunch } from "@mui/icons-material";
import MKTypography from "components/MKTypography";
import { styled } from "@mui/system";
import MKBox from "components/MKBox";

// Styled Card component with hover effect and pointer cursor
const HoverCard = styled(Card)`
  transition: transform 0.5s ease;
  cursor: pointer; /* Change cursor to pointer on hover */
  &:hover {
    transform: translateY(-10px);
  }
`;

const VisionMissionCard = () => {
  return (
    <MKBox
      component="section"
      my={2}
      sx={{
        mx: { xs: 2, sm: 10, md: 10, lg: 10 , xl: 12 }, // Dynamic margin based on screen size
        px: { xs: 2, sm: 10, md: 10, lg: 10, xl: 12 }, // Dynamic padding based on screen size
        py: 2, // Fixed padding for all screen sizes
      }}
    >

      <Container maxWidth="md">
        <Grid container spacing={2} justifyContent="center" component="section">
          <Grid item xs={12} sm={6} md={4}>
            <HoverCard sx={{ maxWidth: 400 }}>
            <CardContent sx={{ height: 300, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1}>
                  <Headphones fontSize="large" />
                </Box>
                <MKTypography variant="h6" component="h2" gutterBottom align="center">
                  Mission
                </MKTypography>
                <MKTypography variant="body2" color="text.secondary" paragraph align="center">
                  Revolutionize the way businesses and individuals perceive and interact with geospatial data
                </MKTypography>
              </CardContent>
            </HoverCard>
          </Grid>
          {/* Card Vision */}
          <Grid item xs={12} sm={6} md={4}>
            <HoverCard sx={{ maxWidth: 400 }}>
            <CardContent sx={{ height: 300, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1}>
                  <Spa fontSize="large" />
                </Box>
                <MKTypography variant="h6" component="h2" gutterBottom align="center">
                  Vision
                </MKTypography>
                <MKTypography variant="body2" color="text.secondary" paragraph align="center">
                  Petain envisions a world where geospatial intelligence is seamlessly integrated into every facet of decision-making.
                </MKTypography>
              </CardContent>
            </HoverCard>
          </Grid>
          {/* Card Goals */}
          <Grid item xs={12} sm={6} md={4}>
            <HoverCard sx={{ maxWidth: 400 }}>
            <CardContent sx={{ height: 300, textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                <Box display="flex" alignItems="center" justifyContent="center" marginBottom={1}>
                  <RocketLaunch fontSize="large" />
                </Box>
                <MKTypography variant="h6" component="h2" gutterBottom align="center">
                  Goals
                </MKTypography>
                <MKTypography variant="body2" color="text.secondary" paragraph align="center">
                  Leading Provider of Location data & geospatial technology by providing the best GIS, insights and Mapping Technologies
                </MKTypography>
              </CardContent>
            </HoverCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
};

export default VisionMissionCard;
