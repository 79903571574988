/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultReviewCard from "examples/Cards/ReviewCards/DefaultReviewCard";

// Images
import gsnLogo from "assets/images/logos/gray-logos/GSN Logo.png";
import mapidLogo from "assets/images/logos/gray-logos/Logo MAPID.png";
import ugmLogo from "assets/images/logos/gray-logos/Logo UGM.png";
import bpnLogo from "assets/images/logos/gray-logos/Logo_BPN-KemenATR_(2017).png";
import oreconLogo from "assets/images/logos/gray-logos/Orecon Logo.png";
import twenteLogo from "assets/images/logos/gray-logos/University Twente Logo.png";

function Information() {
  return (
    <MKBox component="section" >
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          justifyContent="center"
          sx={{ mx: "auto", textAlign: "center" }}
        >
          <MKTypography variant="h1">Our Partners</MKTypography>
          {/* <MKTypography variant="h2" color="info" textGradient mb={2}>
            1,679,477+ web developers
          </MKTypography> */}
          <MKTypography variant="body1" color="text" mb={2}>
          We are partnering with the best institutions in the geospatial world.
          </MKTypography>
        </Grid>
        {/* <Grid container spacing={3} sx={{ mt: 8 }}>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
              name="Bowo nak Tiktok"
              color="light"
              // date="1 day ago"
              review="I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!"
              // rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
          color="light"
              name="jagad semesta alam"
              // date="1 week ago"
              review="I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!"
              // rating={5}
            />
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <DefaultReviewCard
            color="light"
              name="laksamana kuma dilaot"
              // date="3 weeks ago"
              review="I found solution to all my design needs from Creative Tim. I use them as a freelancer in my hobby projects for fun! And its really affordable, very humble guys !!!"
              // rating={5}
            />
          </Grid>
        </Grid> */}
        <Divider sx={{ my: 6 }} />
        <Grid container spacing={3} justifyContent="center">
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={gsnLogo} alt="Apple" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" sx={{paddingTop: "15px"}} src={mapidLogo} alt="Facebook" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img"sx={{paddingTop:"0px", padding:"35px", marginTop:"-50px"}} src={ugmLogo} alt="Nasa" width="100%" opacity={0.6} />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={oreconLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid>
          <Grid item xs={6} md={4} lg={2}>
            <MKBox component="img" src={bpnLogo} sx={{paddingTop:"0px", padding:"35px", marginTop:"-50px"}}  alt="Vodafone" width="100%" opacity={0.6}  />
          </Grid>
          {/* <Grid item xs={6} md={4} lg={2}>
            <MKBox
              component="img"
              src={twenteLogo}
              alt="DigitalOcean"
              width="100%"
              opacity={0.6}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
