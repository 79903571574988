/* eslint-disable react/jsx-no-duplicate-props */
/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";

// Images
import macbook from "assets/images/Ipad & Phone View.png";

function Newsletter() {
  return (
    <MKBox component="section" pt={1} my={6}>
      <Container>
        <Grid container alignItems="center">
          <Grid item md={4} sx={{ ml: { xs: 0, lg: 3 }, mb: { xs: 12, md: 0 } }}>
            <MKTypography variant="h2">Need More Information?</MKTypography>
            <MKTypography variant="body1" color="text" mb={3}>
            just catch up with petain team at info@petain.id 
            </MKTypography>
    
            <MKBox display="flex">
              <MKTypography component="a" variant="body1" color="dark" href="https://wa.me/6282246245824" mr={3} style={{ fontSize: '40px' }}>
                <i className="fa fa-whatsapp" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="dark" href="#" mr={3} style={{ fontSize: '40px' }}>
                <i className="fa fa-linkedin" />
              </MKTypography>
              <MKTypography component="a" variant="body1" color="dark" href="https://www.instagram.com/petainid/" mr={3} style={{ fontSize: '40px' }}>
                <i className="fab fa-instagram" />
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={macbook} alt="macbook" width="100%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Newsletter;
