/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";
import logoPlace from "assets/images/Logo-Petain-Place.png";
import Divider from "@mui/material/Divider";
function DesignPlace() {

  return (
    <MKBox component="section" my={2} py={2}>
      <Container>
      <Divider sx={{ my: 6 }} />
        <Grid
          container
          item
          xs={12}
          lg={9}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 0.75 }}
        >
            <MKBox>
            <img
                src={logoPlace}
                alt="Brand Logo"
                width={130} // ganti dengan lebar yang diinginkan
                height={100} //
              />
              </MKBox>
          <MKTypography variant="h1" fontWeight="bold">
          One Stop Geospatial Solutions


          </MKTypography>
          <MKTypography variant="body1" color="black" my={2}>
         Petain Place Democratizes geospatial technologies, providing a one-stop solution for all geospatial products and services. It simplifies the sourcing, transaction, procurement, monitoring, and delivery of geospatial solutions.
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignPlace;
