// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/petain.svg";
import { Instagram, LinkedIn, WhatsApp } from "@mui/icons-material";
;

const date = new Date().getFullYear();

export default {
  brand: {
    name: "PT Petain Bumi Nusantara (PETAIN) Signature by Regus, South Quarter Tower C Lantai 10 Jalan RA Kartini Kav 8, Cilandak Jakarta, 12430 info@petain.id",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <Instagram />,
      // link: "https://www.facebook.com/CreativeTim/",
    },
    {
      icon: <WhatsApp />,
      // link: "https://twitter.com/creativetim",
    },
    // {
    //   icon: <TwitterIcon />,
    //   // link: "https://github.com/creativetimofficial",
    // },
    {
      icon: <LinkedIn />,
      // link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us" },
        // { name: "freebies"},
        // { name: "premium tools", href: "https://www.creative-tim.com/templates/premium" },
        // { name: "blog", href: "https://www.creative-tim.com/blog" },
      ],
    },
    {
      name: "products",
      items: [
        { name: "Petain Pixel" },
        { name: "Petain place" },
        { name: "Petain for Business" },
      ],
    },
    {
      name: "resources",
      items: [
        { name: "data" },
        { name: "partners" },
        // { name: "affiliate program" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href:"https://wa.me/6282246245824"},
        { name: "knowledge center" },
        { name: "terms & Condition", href: "/term-condition" },
        // { name: "sponsorships", href: "https://www.creative-tim.com/sponsorships" },
      ],
    },
    // {
    //   name: "legal",
    //   items: [
    //     { name: "terms & conditions", href: "https://www.creative-tim.com/terms" },
    //     { name: "privacy policy", href: "https://www.creative-tim.com/privacy" },
    //     { name: "licenses (EULA)", href: "https://www.creative-tim.com/license" },
    //   ],
    // },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date} petain bumi nusantara{" "}
      <MKTypography
        component="a"
        // href="https://www.creative-tim.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
      </MKTypography>
      .
    </MKTypography>
  ),
};
