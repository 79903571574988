/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function DesignTitle() {

  return (
    <MKBox component="section" my={2} py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={9}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h1" my={2}>
          Optimize Your Location Data. Anytime, Anywhere.
          </MKTypography>
          <MKTypography variant="body1" color="black" my={2}>
          Unlock the potential of geospatial technology to revolutionize business strategies and decision-making processes. Our comprehensive suite of geospatial solutions provides businesses with actionable insights derived from spatial data analysis, enhancing operational efficiency, optimizing resource allocation, and enabling informed decision-making
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignTitle;
