/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKTypography from "components/MKTypography";
import macbook from "assets/images/Ipad & Phone View.png";
function DesignTitle() {

  return (
    <MKBox component="section" my={2} py={2}>
      <Container>
      <Grid container item xs={12} justifyContent="center" mx="auto">
      <MKBox mt={{ xs: -20, md: -40 }} textAlign="center">
      <MKBox
              component="img"
              src={macbook}
              alt="macbook"
              width="70%"
              sx={({ breakpoints }) => ({
                [breakpoints.down("sm")]: {
                  width: "100%", // Adjust image width for smaller screens
                },
              })}
            />
          </MKBox>
        </Grid>
        <Grid
          container
          item
          xs={12}
          lg={9}
          flexDirection="column"
          alignItems="center"
          sx={{ textAlign: "center", my: 1, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h1">
          Spatial Data for Everyone
          </MKTypography>
          <MKTypography variant="body1" color="black" my={2}>
          Petain is a geospatial technology firm with a vision to revolutionize the interpretation and  utilization of spatial data. Taking into account market insights, where geospatial data is still widely unfamiliar and associated with its complex utilization process, our goal is to simplify and make it accessible to the market. Petain has a mission to build a geospatial ecosystem based on collaboration. With our two main products, Petain Pixel and Petain Place, we aim to provide geospatial data, products, and services that cater to various industries.

          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignTitle;
