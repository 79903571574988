import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import { CheckCircleOutline } from "@mui/icons-material";
import { styled } from "@mui/system";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const tiers = [
  {
    title: "Free Version",
    subheader: "Free Atlas Feature",
    price: "ROCK",
    description: [
      "Complimentary.",
      "Exclusive to Rock maps available in the Atlas.",
      "Craft up to 5 personalised Mosaics with a maximum capacity of 25 Pixels each.",
    ],
    buttonText: "Sign up for free",
    buttonVariant: "contained",
    disabled:false,
    color:"info"
  },
  {
    title: "Premium Version",
    subheader: "Premium Atlas",
    price: "LAVA ",
    description: [
      "Rp 40,000 per month or Rp 400,000 annually.",
      "Exclusive to Rock, People and accessibility category available in the Atlas.",
      "Full entry to all Lava-tier maps within the Atlas.",
      "Limited to 500 requests per month for in-depth exploration.",
      "Expand your horizons with up to 500 Mosaics, each holding up to 500 Pixels.",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
    disabled:true,
    color:"dark"
  },
  {
    title: "Premium Version",
    subheader: "Premium Atlas",
    price: "MAGMA",
    description: [
      "Rp 100,000 per month or Rp 1,000,000 annually.",
      "Exclusive to Rock, People and accessibility category available in the Atlas.",
      "Unlimited exploration of all Lava-tier maps in the Atlas.",
      "Enjoy 2,000 requests per month for seamless navigation.",
      "he power of unlimited Mosaic creations, each with a maximum of 500 Pixels.",
    ],
    buttonText: "Get started",
    buttonVariant: "contained",
    disabled:true,
    color:"dark"
  },
];

export default function Pricing() {
  return (
    <Container maxWidth="md" component="main">
      <Grid container spacing={5} alignItems="flex-end">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card sx={{ border: "1px solid #ccc", minHeight: 600 }}> {/* Set maximum height */}
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: "center" }}
                action={tier.title === "Pro" ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                //   backgroundColor: "info.main",
                //   color: "info.contrastText",
                  borderRadius:2
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column", // Change to column layout
                    justifyContent: "center",
                    alignItems: "center", // Align items center
                    mb: 2,
                  }}
                >
                    <MKButton  variant="gradient" color="info">
                    {tier.price}
                    </MKButton>
                  {/* <Typography component="h2" variant="h3" color="text.primary" align="center">
                    {tier.price}
                  </Typography> */}
                  {/* <Typography variant="h6" color="text.secondary" align="center">
                    /mo
                  </Typography> */}
                </Box>
                <PricingList>
                  {tier.description.map((line) => (
                    <MKTypography
                    color="text"
                    //   component="li"
                      variant="body2"
                      align="left"
                      key={line}
                      sx={{
                        display: "flex",
                        alignItems: "flex-start", // Align items flex-start
                      }}
                    >
                      <CheckCircleOutline sx={{ mr: 1, color: "success.main" }} /> {/* Icon checklist */}
                      {line}
                    </MKTypography>
                  ))}
                </PricingList>
              </CardContent>
              <Box sx={{ flexGrow: 1 }} /> {/* Spacing to push CardActions to the bottom */}
              <CardActions sx={{ justifyContent: "center", bgcolor: "white", color:"white" }}> {/* Center align actions */}
              <MKButton fullWidth variant="gradient" color={tier.color} disabled={tier.disabled}>
              {tier.buttonText}
                </MKButton>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
