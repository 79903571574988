/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/Petain-Place-Box.png";
import bgBack from "assets/images/Petain-Place-Box.png";

function InformationPlace() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                icon="public" 
                  title="Clarity and Efficiency"
                  description="Helps businesses recognize location characteristics and determine the right locations for business development."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                    icon="public" 
                  title="Ease of Use"
                  description="Categorized into various age groups such as Gen Z, Baby Boomer, and Millennials, as well as economic status categories."
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                           icon="public" 
                  title="Time-Saving"
                  description="Creating your design from scratch with dedicated designers can be very expensive. Start with our Design System."
                />
              </Grid>
              <Grid item xs={12} md={6}>
              {/* <a href="/" tabIndex="0">
              <img
                className="bn46"
                src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                alt="bn45"
                style={bn46Style}
              />
            </a> */}
                <DefaultInfoCard
                     icon="public" 
                  title="Future Implications"
                  description="Regardless of the screen size, the website content will naturally fit the given resolution."
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                   PETAIN

                   PLACE
                  </>
                }
                // description="it easier and simpler to answer location-related questions.
                // "
              />
              <RotatingCardBack
                image={bgBack}
                title={
                    <>
                     PETAIN
                   
                     PLACE
                    </>
                  }
                // description="it easier and simpler to answer location-related questions.
                // "
                action={{
                  type: "internal",
                  route: "/pages/petain-place",
                  label: "Learn More",
                }}
              />
            </RotatingCard>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InformationPlace;
