/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";
import MKTypography from "components/MKTypography";

function DesignTitle() {

  return (
    <MKBox component="section" my={2} py={2}>
      <Container>
        <Grid
          container
          item
          xs={12}
          lg={6}
          flexDirection="column"
          alignItems="left"
          sx={{ textAlign: "left", my: 1, mx: "auto", px: 0.75 }}
        >
          <MKTypography variant="h2" color="info" fontWeight="bold">
          Terms & Conditions
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Welcome to Petain Pixel App
          </MKTypography>
          <MKTypography variant="body1" color="text">
          These terms and conditions outline the rules and regulations for the use of Petain Pixel App, operated by PT. Petain Bumi Nusantara.
          </MKTypography>
          <MKTypography variant="body1" color="text">
          By accessing this app we assume you accept these terms and conditions in full. Do not continue to use the Petain Pixel App if you do not accept all of the terms and conditions stated on this page.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Service Description
          </MKTypography>
          <MKTypography variant="body1" color="text">
          Petain Pixel App provides pixel-based mapping services that allow users to create, edit, share, and explore maps and pixels.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          User Account
          </MKTypography>
          <MKTypography variant="body1" color="text">
          To access certain features of the app, you must register for an account. You are responsible for keeping your account details and password confidential and for all activities under your account.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Content Ownership
          </MKTypography>
          <MKTypography variant="body1" color="text">
          The content displayed on the app, including pixels, maps, text, graphics, and logos, is owned by PT. Petain Bumi Nusantara or its content suppliers and protected by intellectual property rights.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          User Conduct
          </MKTypography>
          <MKTypography variant="body1" color="text">
          You agree not to use the app for any purpose that is illegal, unauthorised, or not in accordance with these terms
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          User-Generated Content
          </MKTypography>
          <MKTypography variant="body1" color="text">
          You may post content, including comments, 'loves', and maps. You are responsible for your content, which must not infringe on any third party’s rights.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Data Use and Protection
          </MKTypography>
          <MKTypography variant="body1" color="text">
          We will handle your personal data in accordance with our Privacy Policy.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Termination
          </MKTypography>
          <MKTypography variant="body1" color="text">
          We may terminate or suspend access to our app immediately, without prior notice, for any reason whatsoever, including without limitation if you breach the terms
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Changes to Terms
          </MKTypography>
          <MKTypography variant="body1" color="text">
          We reserve the right to modify these terms at any time. We will provide notice of any significant changes to the terms and conditions.
          </MKTypography>
          <MKTypography variant="h4" color="black" fontWeight="bold" mt={4}>
          Contact Us
          </MKTypography>
          <MKTypography variant="body1" color="text">
          If you have any questions about these terms, please contact us at help@petain.id or +6221-388-1841
          </MKTypography>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default DesignTitle;
