/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const imagesPrefix =
  "https://raw.githubusercontent.com/creativetimofficial/public-assets/master/material-design-system/presentation/sections";

export default [
  {
    title: "Our Key Services",
    description: "Petain offers services for various industries and needs",
    items: [
      {
        image: `${imagesPrefix}/headers.jpg`,
        icon: "storage",
        name: "Data Collection and Management",
        description:"Gathering and managing geospatial data from various sources like satellite imagery, aerial photography, and field observations.",
        count: 10,
 
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: "Spatial Analysis and Modeling",
        icon: "data_exploration",
        description:"Analyzing geographic data to identify patterns, trends, and relationships.",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: "Mapping and Visualization",
        icon: "leaderboard",
        description:"Creating static and dynamic maps to visualize spatial data",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: "Custom GIS Software Development",
        icon: "wysiwyg",
        description:"Designing and developing customized GIS software tailored to specific business needs and Providing solutions for mobile GIS applications.",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: "Consulting and Advisory Services",
        icon: "face",
        description:"Providing expert advice on implementing GIS technologies in various sectors.",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },
      {
        image: `${imagesPrefix}/headers.jpg`,
        name: "GIS Integration Services",
        icon: "integration_instructions",
        description:"Integrating GIS capabilities with other business systems like CRM, ERP, or data analytics platforms.",
        count: 10,
        route: "/sections/page-sections/page-headers",
      },

    ],
  },
  // {
  //   title: "Navigation",
  //   description: "30+ components that will help go through the pages",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/navbars.jpg`,
  //       name: "Navbars",
  //       count: 4,
  //       route: "/sections/navigation/navbars",
  //     },
  //     {
  //       image: `${imagesPrefix}/nav-tabs.jpg`,
  //       name: "Nav Tabs",
  //       count: 2,
  //       route: "/sections/navigation/nav-tabs",
  //     },
  //     {
  //       image: `${imagesPrefix}/pagination.jpg`,
  //       name: "Pagination",
  //       count: 3,
  //       route: "/sections/navigation/pagination",
  //     },
  //   ],
  // },
  // {
  //   title: "Input Areas",
  //   description: "50+ elements that you need for text manipulation and insertion",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/newsletters.jpg`,
  //       name: "Newsletters",
  //       count: 6,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/contact-sections.jpg`,
  //       name: "Contact Sections",
  //       count: 8,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/forms.jpg`,
  //       name: "Forms",
  //       count: 3,
  //       route: "/sections/input-areas/forms",
  //     },
  //     {
  //       image: `${imagesPrefix}/inputs.jpg`,
  //       name: "Inputs",
  //       count: 6,
  //       route: "/sections/input-areas/inputs",
  //     },
  //   ],
  // },
  // {
  //   title: "Attention Catchers",
  //   description: "20+ Fully coded components that popup from different places of the screen",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/alerts.jpg`,
  //       name: "Alerts",
  //       count: 4,
  //       route: "/sections/attention-catchers/alerts",
  //     },
  //     {
  //       image: `${imagesPrefix}/toasts.jpg`,
  //       name: "Notifications",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/popovers.jpg`,
  //       name: "Tooltips & Popovers",
  //       count: 2,
  //       route: "/sections/attention-catchers/tooltips-popovers",
  //     },
  //     {
  //       image: `${imagesPrefix}/modals.jpg`,
  //       name: "Modals",
  //       count: 5,
  //       route: "/sections/attention-catchers/modals",
  //     },
  //   ],
  // },
  // {
  //   title: "Elements",
  //   description: "80+ carefully crafted small elements that come with multiple colors and shapes",
  //   items: [
  //     {
  //       image: `${imagesPrefix}/buttons.jpg`,
  //       name: "Buttons",
  //       count: 6,
  //       route: "/sections/elements/buttons",
  //     },
  //     {
  //       image: `${imagesPrefix}/avatars.jpg`,
  //       name: "Avatars",
  //       count: 2,
  //       route: "/sections/elements/avatars",
  //     },
  //     {
  //       image: `${imagesPrefix}/dropdowns.jpg`,
  //       name: "Dropdowns",
  //       count: 2,
  //       route: "/sections/elements/dropdowns",
  //     },
  //     {
  //       image: `${imagesPrefix}/switch.jpg`,
  //       name: "Toggles",
  //       count: 2,
  //       route: "/sections/elements/toggles",
  //     },
  //     {
  //       image: `${imagesPrefix}/social-buttons.jpg`,
  //       name: "Social Buttons",
  //       count: 2,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/breadcrumbs.jpg`,
  //       name: "Breadcrumbs",
  //       count: 1,
  //       route: "/sections/elements/breadcrumbs",
  //     },
  //     {
  //       image: `${imagesPrefix}/badges.jpg`,
  //       name: "Badges",
  //       count: 3,
  //       route: "/sections/elements/badges",
  //     },
  //     {
  //       image: `${imagesPrefix}/progress.jpg`,
  //       name: "Progress Bars",
  //       count: 4,
  //       route: "/sections/elements/progress-bars",
  //     },
  //     {
  //       image: `${imagesPrefix}/tables.jpg`,
  //       name: "Tables",
  //       count: 3,
  //       pro: true,
  //     },
  //     {
  //       image: `${imagesPrefix}/typography.jpg`,
  //       name: "Typography",
  //       count: 2,
  //       route: "/sections/elements/typography",
  //     },
  //   ],
  // },
];
