/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKAvatar from "components/MKAvatar";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import { Button } from "@mui/material";
import Divider from "@mui/material/Divider";
// Images
import profilePicture from "assets/images/bruce-mars.jpg";
import logoPixel from "assets/images/petain.svg";
function Profile() {
  return (
    <MKBox component="section" py={{ xs: 6, sm: 12 }}>
      <Container>
        <Grid container item xs={12} justifyContent="center" mx="auto">
          <MKBox mt={{ xs: -16, md: -20 }} textAlign="center">
            <MKAvatar src={profilePicture} alt="Burce Mars" size="xxl" shadow="xl" />
          </MKBox>
          <Grid container justifyContent="center">
            <Grid item xs={12} md={7} mx={{ xs: "auto", sm: 6, md: 1 }}>
              <MKBox display="flex" justifyContent="space-between" alignItems="center">
              <MKTypography variant="h3">Account</MKTypography>
              <MKBox>
            <img
                src={logoPixel}
                alt="Brand Logo"
                width={110} // ganti dengan lebar yang diinginkan
                height={90} // ganti dengan tinggi yang diinginkan
              />
              </MKBox>
           
              </MKBox>
              <Divider orientation="horizontal" sx={{ display: { xs: "none", md: "block" }, mx: 0 }} />
   
              <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={4}>
                  <MKTypography variant="body1">Email Account</MKTypography>
                </Grid>

                <Grid item xs={12} sm={5}>
                <MKTypography variant="text">Agamindra0@gmail.com</MKTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                <MKButton variant="outlined" color="info" size="small">
                    Manage Account
                  </MKButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKTypography variant="body1">Plan Detail</MKTypography>
                </Grid>

                <Grid item xs={12} sm={5}>
                <MKButton  color="info" size="small">
                   Free Version - Rocks
                  </MKButton>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MKButton href="/pages/upgrades-plan" variant="outlined" color="info" size="small">
                    Upgrades Plan
                  </MKButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKTypography variant="body1">Report Problem</MKTypography>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <MKTypography variant="body1"></MKTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MKButton variant="outlined" color="info" size="small">
                    Report
                  </MKButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKTypography variant="body1">Terms & Condition</MKTypography>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <MKTypography variant="body1"></MKTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MKButton  variant="outlined" color="info" size="small" href="/term-condition">
                    learn more
                  </MKButton>
                </Grid>
                <Grid item xs={12} sm={4}>
                  <MKTypography variant="body1">Support Center</MKTypography>
                </Grid>

                <Grid item xs={12} sm={5}>
                  <MKTypography variant="body1"></MKTypography>
                </Grid>
                <Grid item xs={12} sm={3}>
                  <MKButton variant="outlined" color="info" size="small">
                    contact support
                  </MKButton>
                </Grid>
                
              </Grid>

            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
