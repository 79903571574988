/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// About Us page sections
import Information from "./sections/Information";
import Team from "pages/LandingPages/AboutUs/sections/Team";
import Featuring from "./sections/Featuring";
import Newsletter from "./sections/Newsletter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

// Images
import bgImage from "assets/images/satelite-banner.jpg";
import DesignTitle from "./sections/DesignTitle";
import BuiltByDevelopers from "./sections/BuiltByDevelopers";

function PetainPlace() {
  return (
    <>
     <DefaultNavbar
        routes={routes}
        action={{
          type: "external",
          // route: "https://www.creative-tim.com/product/material-kit-react",
          label: "",
          color: "info",
        }}
        sticky
      />
     <MKBox
        minHeight="70vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            className="css-1j4rulz-MuiGrid-root"
            style={{ marginLeft: 0, paddingBottom: "75px" }}
            lg={6}
            flexDirection="column"
            alignItems="left"
            sx={{ textAlign: "left", mx: "auto", px: 0.75 }}

          >
            <MKTypography
              variant="h2"
              color="light"
              mt={1}
              textAlign="left"
              mb={1}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["4xl"],
                },
                fontSize: size["4xl"], // Adjust the default size
              })}
            >
            Simplifies and expedites the sourcing, 
            transaction, monitoring, and delivery of geospatial solutions.
            </MKTypography>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <DesignTitle/>
        <BuiltByDevelopers/>
        <Information />
        {/* <BuiltByDevelopers/> */}
        {/* <Team /> */}
        <Featuring />
        <Newsletter />
      </Card>
      <MKBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </MKBox>
    </>
  );
}

export default PetainPlace;
