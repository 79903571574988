/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import bgBack from "assets/images/Petain-Pixel-Page-Banner-3.png";
import logoPixel from "assets/images/Petain Pixel.png";
function Information() {
  const bn46Style = {
    width: '220px',
    height: '80px',
    // Add any additional styles here
  };
  const bn47Style = {
    width: '250px',
    height: '11 0px',
    // Add any additional styles here
  };
  return (
    <MKBox component="section" py={12}>
      <Container>
      <MKBox>
            <img
                src={logoPixel}
                alt="Brand Logo"
                width={110} // ganti dengan lebar yang diinginkan
                height={90} // ganti dengan tinggi yang diinginkan
              />
              </MKBox>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Easy Pixel Map"
                    description="Navigate the world with ease—no GIS expertise needed! Our pixel approach makes map reading as simple as pie."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="payments"
                    title="Local Flavour"
                    description="Search and identify places with a local touch. Our Pixel Address speaks your language.
                    "
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Share and Connect"
                    description="Create 'Mosaics'—your personal maps—and share them with friends, family, or the Petain community."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5} >
                  <DefaultInfoCard
                    icon="3p"
                    title="Inclusive Innovation"
                    description="From small businesses to big thinkers, Petain Pixel is the tool for everyone to leverage geospatial data."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Atlas"
                    description="Explore our Petain Atlas, brimming with data from street-level to global insights, all at your fingertips for smart exploring and savvy decisions."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2} justify="center">
                  {/* <Grid item xs={12} md={6}> */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <a href="/" tabIndex="0">
                        <img
                          className="bn46"
                          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                          alt="bn45"
                          style={bn46Style}
                        />
                      </a>
                      <a href="/" tabIndex="0">
                        <img
                          className="bn47"
                          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                          alt="bn45"
                          style={bn47Style}
                        />
                      </a>
                    </div>
                  {/* </Grid> */}
                </Grid>

              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image={bgBack}
              // title="Get insights on Search"
              // description="Website visitors today demand a frictionless user expericence — especially when using search. Because of the hight standards."
              // action={{
              //   type: "internal",
              //   // route: "pages/company/about-us",
              //   // color: "info",
              //   // label: "find out more",
              // }}
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
