/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";
import bgBack from "assets/images/form-mapid-2.png";
import SectionInfoCard from "examples/Cards/InfoCards/SectionInfoCard";
function InformationCollection() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <Grid container justifyContent="flex-start">
              <Grid>
                <MKBox>
                  <SectionInfoCard
                     icon="public"
                    title="GEO Collection"
                    description="FORM MAPID is a mobile app for digital forms, collecting public data via links. With 17 data formats, it supports real-time reporting and collaboration for government services like healthcare, local tourism, and disaster early warning."
                  />
                </MKBox>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={5} sx={{ ml: "auto" }}>
            <MKBox position="relative">
              <MKBox component="img" src={bgBack} alt="macbook" width="70%" />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default InformationCollection;
