/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import RotatingCard from "examples/Cards/RotatingCard";
import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

// Images
import bgFront from "assets/images/pixel-box.png";
import bgBack from "assets/images/pixel-box.png";

function Information() {
  const bn46Style = {
    width: '220px',
    height: '80px',
    // Add any additional styles here
  };
  const bn47Style = {
    width: '250px',
    height: '11 0px',
    // Add any additional styles here
  };
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                icon="touch_app"
                title={
                  <>
                   PETAIN

                   PIXEL
                  </>
                }
              />
              <RotatingCardBack
                image={bgBack}
                title={
                  <>
                   PETAIN

                   PIXEL
                  </>
                }
                action={{
                  type: "internal",
                  route: "/pages/petain-pixel",
                  label: "lEARN MORE",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={1}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                        icon="public" 
                  title="Easy Pixel Map"
                  description="Navigate the world with ease—no GIS expertise needed! Our pixel approach makes map reading as simple as pie.
                  "
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                     icon="public" 
                  title="Local Flavour"
                  description="Search and identify places with a local touch. Our Pixel Address speaks your language."
                />
              </Grid>
            </Grid>
            <Grid container spacing={1} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
              <MKBox mb={{ xs: 5, md: 0 }}>
                <DefaultInfoCard
               icon="public" 
                  title="Share and Connect"
                  description="Create 'Mosaics'—your personal maps—and share them with friends, family, or the Petain community."
                />
              </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <Grid container spacing={2} justify="center">
                  {/* <Grid item xs={12} md={6}> */}
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                      <a href="/" tabIndex="0">
                        <img
                          className="bn46"
                          src="https://developer.apple.com/assets/elements/badges/download-on-the-app-store.svg"
                          alt="bn45"
                          style={bn46Style}
                        />
                      </a>
                      <a href="/" tabIndex="0">
                        <img
                          className="bn47"
                          src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
                          alt="bn45"
                          style={bn47Style}
                        />
                      </a>
                    </div>
                  {/* </Grid> */}
                </Grid>

              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
